import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Image, Form, Input, notification, Select, Button, Card } from "antd";
import "../../assets/style/editTambah.css"

const { Option } = Select;

function TambahForm() {
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const openNotificationWithIcon = (type, title, desc) => {
    api[type]({
      message: title,
      description: desc,
    });
  };

  const [NamaLengkap, setNamaLengkap] = useState("");
  const [JnsToilet, setJnsToilet] = useState("");
  const [JnsKamarMandi, setJnsKamarMandi] = useState("");
  const [JnsRangkaDinding, setJnsRangkaDinding] = useState("");
  const [JnsLantai, setJnsLantai] = useState("");
  const [Ventilasi, setVentilasi] = useState("");
  const [Pmbuangan_air, setPmbuangan_air] = useState("");
  const [Sdia_dapur, setSdia_dapur] = useState("");
  const [Pencahayaan, setPencahayaan] = useState("");
  const [Smber_air, setSmber_air] = useState("");
  const [StatusRumah, setStatusRumah] = useState("");

  async function handleSubmit() {
    const body = {
      nama_lengkap: NamaLengkap,
      sdia_toilet: JnsToilet,
      jenis_kmrMandi: JnsKamarMandi,
      rngka_dinding: JnsRangkaDinding,
      jns_lantai: JnsLantai,
      ventilasi: Ventilasi,
      pmbuangan_air: Pmbuangan_air,
      sdia_dapur: Sdia_dapur,
      pencahayaan: Pencahayaan,
      smber_air: Smber_air,
      status: StatusRumah,
    };

    const headers = {
      "ngrok-skip-browser-warning": "69420",
      "Content-type": "application/json",
      Accept: "application/json",
    };

    try {
      await axios.post(`https://api.rusdaca.com/data/tambahdata`, body, {
        headers: headers,
      });
      openNotificationWithIcon("success", "Success", "Data berhasil ditambahkan");
      setTimeout(() => {
        navigate("/datawarga");
      }, 1000);
    } catch (error) {
      console.error("Terjadi kesalahan saat menambahkan data:", error);
      openNotificationWithIcon("error", "Error", "Gagal menambahkan data");
    }
  }

  return (
    <div className="container" style={{ display: "flex", alignItems: "flex-start", gap: "20px" }}>
      {contextHolder}
      <Card className="container-card">
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          labelAlign="left"
          colon={false}
          requiredMark={false}
          style={{ flex: 1}}

        >
          <Form.Item
            label="Nama Lengkap"
            rules={[{ required: true }]}
            className="form-item"
            style={{ marginBottom: '19px' }}
          >
            <Input value={NamaLengkap} onChange={(e) => setNamaLengkap(e.target.value)} />
          </Form.Item>

          <Form.Item
            label="Ketersediaan Toilet"
            rules={[{ required: true }]}
            className="form-item"
            style={{ marginBottom: '19px' }}
          >
            <Select value={JnsToilet} onChange={(value) => setJnsToilet(value)}>
              <Option value="ada">Ada</Option>
              <Option value="tidak ada">Tidak Ada</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Jenis Kamar Mandi"
            rules={[{ required: true }]}
            className="form-item"
            style={{ marginBottom: '19px' }}
          >
            <Select value={JnsKamarMandi} onChange={(value) => setJnsKamarMandi(value)}>
              <Option value="pribadi">Pribadi</Option>
              <Option value="umum">Umum</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Jenis Rangka Dinding"
            rules={[{ required: true }]}
            className="form-item"
            style={{ marginBottom: '19px' }}
          >
            <Select value={JnsRangkaDinding} onChange={(value) => setJnsRangkaDinding(value)}>
              <Option value="beton">Beton</Option>
              <Option value="lainnya">Lainnya</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Jenis Lantai"
            rules={[{ required: true }]}
            className="form-item"
            style={{ marginBottom: '19px' }}
          >
            <Select value={JnsLantai} onChange={(value) => setJnsLantai(value)}>
              <Option value="marmer">Marmer</Option>
              <Option value="keramik">Keramik</Option>
              <Option value="lainnya">Lainnya</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Ventilasi"
            rules={[{ required: true }]}
            className="form-item"
            style={{ marginBottom: '19px' }}
          >
            <Select value={Ventilasi} onChange={(value) => setVentilasi(value)}>
              <Option value="cukup">Cukup</Option>
              <Option value="tidak cukup">Tidak Cukup</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Pembuangan Air"
            rules={[{ required: true }]}
            className="form-item"
            style={{ marginBottom: '19px' }}
          >
            <Select value={Pmbuangan_air} onChange={(value) => setPmbuangan_air(value)}>
              <Option value="ada">Ada</Option>
              <Option value="tidak ada">Tidak Ada</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Ketersediaan Dapur"
            rules={[{ required: true }]}
            className="form-item"
            style={{ marginBottom: '19px' }}
          >
            <Select value={Sdia_dapur} onChange={(value) => setSdia_dapur(value)}>
              <Option value="ada">Ada</Option>
              <Option value="tidak ada">Tidak Ada</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Pencahayaan"
            rules={[{ required: true }]}
            className="form-item"
            style={{ marginBottom: '19px' }}
          >
            <Select value={Pencahayaan} onChange={(value) => setPencahayaan(value)}>
              <Option value="cukup">Cukup</Option>
              <Option value="tidak cukup">Tidak Cukup</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Sumber Air"
            rules={[{ required: true }]}
            className="form-item"
            style={{ marginBottom: '19px' }}
          >
            <Select value={Smber_air} onChange={(value) => setSmber_air(value)}>
              <Option value="PAM">PAM</Option>
              <Option value="sungai">Sungai</Option>
              <Option value="sumur">Sumur</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Status Rumah"
            rules={[{ required: true }]}
            className="form-item"
            style={{ marginBottom: '19px' }}
          >
            <Input value={StatusRumah} onChange={(e) => setStatusRumah(e.target.value)} readOnly />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 15 }} >
            <Button type="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Image src={require("../../assets/images/undraw_data_input_fxv2.png")} preview={false} style={{ maxWidth: '400px', marginTop: "100px", marginLeft: "100px" }} />

    </div>
  );
}

export default TambahForm;

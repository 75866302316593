import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const Protected = ({ children, isAuthenticated }) => {
    if (!isAuthenticated) {
        return <Navigate to="/dashboard" replace />;
    }
    return children ;
};

export default Protected;

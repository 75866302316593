import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { UserOutlined, HomeOutlined, RightCircleOutlined, LeftCircleOutlined, DatabaseOutlined, LogoutOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
// import "../../assets/style/sideBar.css"

const SideBar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear authentication data here
    localStorage.removeItem('token'); // Example for token
    // Redirect to login page or home page
    navigate('/');
  };

  const menuItem = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: <HomeOutlined />
    },
    {
      path: "/datawarga",
      name: "Data Rumah",
      icon: <DatabaseOutlined />
    },
    {
      path: "/data/user",
      name: "Data Akun Pengguna",
      icon: <UserOutlined />
    }
  ];

  return (
    <div className='container'>
      <div className='sidebar' style={{ width: isOpen ? "200px" : "50px", display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <div className='top_section' style={{ flex: '0 1 auto', marginBottom:isOpen ? "70px" : "" }}>
          <img src={require('../../assets/images/Logo.png')} className='logo' style={{ display: isOpen ? "block" : "none" }} />
          <div className='bars' style={{ marginLeft: isOpen ? "150px" : "0px" }}>
            <RightCircleOutlined style={{ display: isOpen ? "none" : "block" }} onClick={toggle} />
            <LeftCircleOutlined style={{ display: isOpen ? "block" : "none" }} onClick={toggle} />
          </div>
        </div>
        <div className='menu_items' style={{ flex: '1 1 auto' }}>
          {
            menuItem.map((item, index) => (
              <Tooltip placement='right' title={item.name} key={index}>
                <NavLink 
                  to={item.path} 
                  className={({ isActive }) => isActive ? "link active" : "link"}
                >
                  <div className='icon'>{item.icon}</div>
                  <div className='link_text' style={{ display: isOpen ? "block" : "none" }}>{item.name}</div>
                </NavLink>
              </Tooltip>
            ))
          }
        </div>
        <div className='logout_section' style={{ marginTop: 'auto', flex: '0 1 auto' }}>
          <Tooltip placement='right' title="Logout">
            <div className='link' onClick={handleLogout}>
              <LogoutOutlined />
              <span style={{ display: isOpen ? "block" : "none" }}>Logout</span>
            </div>
          </Tooltip>
        </div>
      </div>
      <main className='main' style={{ width: isOpen ? "calc(100vw - 200px)" : "calc(100vw - 50px)" }}>{children}</main>
    </div>
  );
};

export default SideBar;

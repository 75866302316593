import React, { useState } from "react";
import axios from "axios";
import '../../assets/style/admin.css'; 
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

const Admin = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    
    const openNotificationWithIcon = (type, title, desc) => {
        api[type]({
            message: title,
            description: desc
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post('https://api.rusdaca.com/auth/admin/login', { email, password });
            console.log('Login successful:', response.data);
            const {token} = response.data
            // Save the token to localStorage
            localStorage.setItem('token', token);

            openNotificationWithIcon("success", "Berhasil Masuk", "Anda berhasil masuk.");
                navigate("/dashboard");
        } catch (err) {
            openNotificationWithIcon("error", "Error", "Email atau password salah.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="login-container">
            {contextHolder}
            <div className="logo">
                <img src={require('../../assets/images/Logo.png')} width={150} />
            </div>

            <form className="formLogin" onSubmit={handleSubmit}>
                <h2>Admin Login</h2>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group password-group">
                    <label htmlFor="password">Password</label>
                    <div className="password-container">
                        <input
                            type={passwordVisible ? "text" : "password"}
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <span 
                            className="password-toggle"
                            onClick={() => setPasswordVisible(!passwordVisible)}
                        >
                            {passwordVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                        </span>
                    </div>
                </div>
                {error && <p className="error-message">{error}</p>}
                <button type="submit" disabled={loading}>
                    {loading ? "Logging in..." : "Login"}
                </button>
            </form>
        </div>
    );
};

export default Admin;

import "../../assets/style/datawarga.css";
import React, { useState, useEffect } from 'react';
import { Space, Table, Tooltip, Button,notification, Input } from 'antd';
import axios from 'axios';
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { ExportToExcel } from "../../exportExcel/xlsx";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';  // Import SweetAlert

const DataWarga = () => {
    const { Search } = Input;
    const [searchText, setSearchText] = useState("");
    const [dataRumahSehat, setDataRumahSehat] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [excel, getDataExcel] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get('https://api.rusdaca.com/data/ambildata', {
                headers: {
                    "ngrok-skip-browser-warning": "69420",
                    "Content-type": "application/json",
                    Accept: "application/json"
                }
            });
            console.log(response)
            const dataWithIds = response.data.data.map(item => ({
                ...item,
                rand: item.rand
            }));
            setDataRumahSehat(dataWithIds);
            setFilteredData(dataWithIds);
            console.log(response)
            const datas = response.data.data;
            const convert = datas?.map((item, index) => ({
                No: index + 1,
                NamaLengkap: item.nama_lengkap,
                // NIK: item.nik,
                // LuasRumah: item.luas_rumah,
                // JmlPenghuni: item.jml_penghuni,
                JnsToilet: item.sdia_toilet,
                JnsKamarMandi: item.jenis_kmrMandi,
                JnsRangkaDinding: item.rngka_dinding,
                JnsLantai: item.jns_lantai,
                Ventilasi: item.ventilasi,
                pembuangan_air: item.pmbuangan_air,
                ketersediaan_dapur: item.sdia_dapur,
                pencahayaan: item.pencahayaan,
                sumber_air: item.smber_air,
                StatusRumah: item.status
            }));
            getDataExcel(convert);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearch = (e) => {
        const value = e.target.value;
        setSearchText(value);
        const filtered = dataRumahSehat.filter((item) =>
            item.nama_lengkap.toLowerCase().includes(value.toLowerCase()) ||
            item.status.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredData(filtered);
    };

    const editData = (record) => {
        const rand = record.rand;
        navigate(`/data/editData/${rand}`);
    };

    const tambahData = () => {
        navigate('/data/tambah')
    }

    const exportData = <ExportToExcel apiData={excel} fileName="Data Rumah Desa Candinata" />;

    // Handle delete data confirmation with SweetAlert
    const showDeleteConfirm = (record) => {
        Swal.fire({
            title: 'Apakah yakin ingin menghapus data ini?',
            text: `Data milik ${record.nama_lengkap} akan dihapus secara permanen.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, Hapus',
            cancelButtonText: 'Batal'
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteData(record._id);
            }
        });
    };

    const handleDeleteData = async (id) => {
        try {
            await axios.delete(`https://api.rusdaca.com/data/hapusdata/${id}`, {
                headers: {
                    "ngrok-skip-browser-warning": "69420",
                    "Content-type": "application/json",
                    Accept: "application/json"
                }
            });
            openNotificationWithIcon("success", "Data Berhasil Dihapus", "Data berhasil dihapus.");
            fetchData();
        } catch (error) {
            console.error(error);
            openNotificationWithIcon("error", "Error Hapus Data", "Terjadi kesalahan saat menghapus data.");
        }
    };

    const openNotificationWithIcon = (type, title, desc) => {
        api[type]({
            message: title,
            description: desc
        });
    };

    const columns = [
        {
            title: "No",
            key: 'index',
            render: (text, record, index) => (page - 1) * pageSize + index + 1,
            width: "5%",
            align: 'center'
        },
        { title: 'Nama Lengkap', dataIndex: 'nama_lengkap', align: 'center', key: 'nama_lengkap' },
        // { title: 'NIK', dataIndex: 'nik', align: 'center', key: 'nik' },
        // { title: 'Luas Rumah (m²)', dataIndex: 'luas_rumah', align: 'center', key: 'luas_rumah' },
        // { title: 'Jumlah Penghuni', dataIndex: 'jml_penghuni', align: 'center', key: 'jml_penghuni' },
        { title: 'Toilet', dataIndex: 'sdia_toilet', align: 'center', key: 'sdia_toilet' },
        { title: 'Jenis Kamar Mandi', dataIndex: 'jenis_kmrMandi', align: 'center', key: 'jenis_kmrMandi' },
        { title: 'Rangka Dinding', dataIndex: 'rngka_dinding', align: 'center', key: 'rngka_dinding' },
        { title: 'Jenis Lantai', dataIndex: 'jns_lantai', align: 'center', key: 'jns_lantai' },
        { title: 'Ventilasi', dataIndex: 'ventilasi', align: 'center', key: 'ventilasi' },
        { title: 'Pembuangan Air', dataIndex: 'pmbuangan_air', align: 'center', key: 'pmbuangan_air' },
        { title: 'Ketersediaan Dapur', dataIndex: 'sdia_dapur', align: 'center', key: 'sdia_dapur' },
        { title: 'Pencahayaan', dataIndex: 'pencahayaan', align: 'center', key: 'pencahayaan' },
        { title: 'Sumber Air', dataIndex: 'smber_air', align: 'center', key: 'smber_air' },
        { title: 'Status Rumah', dataIndex: 'status', align: 'center', key: 'status' },
        {
            title: 'Action',
            align: 'center',
            render: (record) => (
                <Space size="small" className="container-button">
                    <Tooltip title="Edit Data">
                        <Button onClick={() => editData(record)} style={{ width: "30px" }} className="edit-icon">
                            <EditOutlined />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Hapus Data">
                        <Button onClick={() => showDeleteConfirm(record)} style={{ width: "30px" }} className="delete-icon">
                            <DeleteOutlined />
                        </Button>
                    </Tooltip>
                </Space>
            )
        }
    ];

    return (
        <div>
            {contextHolder}
            <div className="toolbar">
                <div style={{
                    display:"flex"
                }}>
                    <div className="export-button">
                        {exportData}
                    </div>
                    {/* <div className="add-button">
                        <Button onClick={tambahData}  style={{
                            backgroundColor: "rgb(42, 143, 238)",
                            height:"38px",
                            borderRadius:"5px",
                            marginTop:"9px",
                            marginLeft:"10px",
                            color:"white"
                        }}>Tambah Data</Button>
                    </div> */}
                </div>
                <div>
                    <h2>Daftar Rumah Warga</h2>
                </div>
                <div className="search-container">
                    <Search
                        placeholder="Search by name or status"
                        onChange={handleSearch}
                        style={{ width: 300 }}
                        enterButton
                    />
                </div>
            </div>
            <Table
                className="ant-table"
                columns={columns}
                dataSource={filteredData}
                pagination={{
                    onChange(current) {
                        setPage(current);
                    },
                    showSizeChanger: true,
                    onShowSizeChange: (current, pageSize) => setPageSize(pageSize),
                }}
                scroll={{ x: 360, y: 442 }}
            />
        </div>
    );
};

export default DataWarga;

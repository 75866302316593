import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Outlet, useNavigate } from 'react-router-dom';
import DashboardPage from './component/halaman/Dashboard';
import "./App.css";
import DataWarga from './component/halaman/DataWarga';
import EditData from './component/halaman/editData';
import DataPengguna from './component/halaman/dataPengguna';
import EditPengguna from './component/halaman/editPengguna';
import Admin from './component/halaman/admin';
import Protected from './protected'; // Import the Protected component
import SideBar from './component/sidebar/sideBarComp'; // Import your sidebar component
import TambahForm from './component/formData/tambahForm';

const Layout = () => {
  return (
    <div>
      <SideBar>
        <main>
          <Outlet /> {/* This is where nested routes will be rendered */}
        </main>
      </SideBar>
    </div>
  );
};

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Default false
  useEffect(() => {
    const token = localStorage.getItem('token');
    console.log("Token in localStorage:", token); // Log token yang ditemukan
    if (token) {
      setIsAuthenticated(true);
      console.log("User is authenticated"); // Log apakah autentikasi sukses
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Admin />} />
        <Route element={<Layout />}> {/* Layout is used here */}
          <Route
            path='/dashboard'
            element={
              <Protected isAuthenticated={isAuthenticated}>
                <DashboardPage />
              </Protected>
            }
          />
          <Route
            path='/datawarga'
            element={
              <Protected isAuthenticated={isAuthenticated}>
                <DataWarga />
              </Protected>
            }
          />
          <Route
            path='/data/editData/:id'
            element={
              <Protected isAuthenticated={isAuthenticated}>
                <EditData />
              </Protected>
            }
          />
          <Route
            path='/data/editPengguna/:id'
            element={
              <Protected isAuthenticated={isAuthenticated}>
                <EditPengguna />
              </Protected>
            }
          />

          <Route
            path='/data/tambah'
            element={
              <Protected isAuthenticated={isAuthenticated}>
                <TambahForm />
              </Protected>
            }
          />
          
          <Route
            path='/data/user'
            element={
              <Protected isAuthenticated={isAuthenticated}>
                <DataPengguna />
              </Protected>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
